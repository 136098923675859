import { fetchBaseQuery } from "@reduxjs/toolkit/query";

const customFetchBase = fetchBaseQuery({
  baseUrl: `https://api.galeno.audifarmsalud.dev`,
  credentials: 'include',
  jsonContentType: 'application/json',
  prepareHeaders: (headers) => {
    return headers;
  },
});

export default customFetchBase;



